import React from "react";

const StatusRender = (props) => {
  switch (props.status ? props.status.toLowerCase() : props.status) {
    case "closed":
      return <p className="as-green-bg as-widget">Closed</p>;
      break;
    case "new":
      return <p className="as-skyblue-bg as-widget">New</p>;
      break;
    case "pending-student":
      return <p className="as-red-bg as-widget">Pending-Student</p>;
      break;
    case "pending-academy":
      return <p className="as-blue-bg as-widget">Pending-Academy</p>;
      break;
    case "pending-investigation":
      return <p className="as-yellowshade-bg as-widget">Pending-Investigation</p>;
      break;
    case "open":
      return <p className="as-yellow-bg as-widget">Open</p>;
      break;
    default:
      return <p>Unknown Status</p>;
      break;
  }
};
export default StatusRender;

const catUi = ({ color, text }) => {
  return (
    <p className={"cat " + color}>
      <i className="fas fa-circle"></i> {text}
    </p>
  )
}
export const CategoryRender = (props) => {
  let str = props.status ? props.status.toLowerCase() : props.status
  var replaced = str ? str.replace(/-/g, ' ') : str;
  switch (replaced) {
    case "other":
      return (catUi({ color: "cat-skyblue", text: "Other" })); break;
    case "studykits":
      return (catUi({ color: "cat-greenhex", text: "Studykits" })); break;
    case "complaint":
      return (catUi({ color: "cat-blue", text: "Complaint" })); break;
    case "assessments":
      return (catUi({ color: "cat-red", text: "Assessments" })); break;
    case "student portal":
      return (catUi({ color: "cat-greenhex", text: "Student Portal" })); break;
    case "external exam query":
      return (catUi({ color: "cat-dark-red", text: "External Exam Query" })); break;
    case "accounts":
      return (catUi({ color: "cat-yellow", text: "Accounts" })); break;
    case "external-exam-query":
      return (catUi({ color: "cat-dark-red", text: "External-Exam-Query" })); break;
    case "internal exam query":
      return (catUi({ color: "cat-dark-red", text: "Internal Exam Query" })); break;
    case "academic query":
      return (catUi({ color: "cat-lightblue", text: "Academic Query" })); break;
    case "new studies":
      return (catUi({ color: "cat-yellowshade", text: "New Studies" })); break;
    case "current studies":
      return (catUi({ color: "cat-yellowshade", text: "Current Studies" })); break;
    case "software":
      return (catUi({ color: "cat-greenhex", text: "Software" })); break;
    case "virtual classes":
      return (catUi({ color: "cat-greenhex", text: "Online/Virtual.." })); break;
    case "portal on hold":
      return (catUi({ color: "cat-yellow", text: "Portal on Hold" })); break;
    case "general account query":
      return (catUi({ color: "cat-yellow", text: "General Account Query" })); break;
    case "statement request":
      return (catUi({ color: "cat-greenhex", text: "Statement Request" })); break;
    case "results witheld":
      return (catUi({ color: "cat-dark-red", text: "Results Withheld" })); break;
    default:
      return (catUi({ color: "cat-dark-red", text: str ? str : 'Unkonwn Status' })); break;
  }
};

export const CatColorRender = (props) => {
  let str = props ? props.toLowerCase() : props
  var replaced = str ? str.replace(/-/g, ' ') : str;
  switch (replaced) {
    case "other":
      return "cat-skyblue"; break;
    case "studykits":
      return "cat-greenhex"; break;
    case "complaint":
      return "cat-blue"; break;
    case "assessments":
      return "cat-red"; break;
    case "student portal":
      return "cat-greenhex"; break;
    case "external exam query":
      return "cat-dark-red"; break;
    case "accounts":
      return "cat-yellow"; break;
    case "external-exam-query":
      return "cat-dark-red"; break;
    case "internal exam query":
      return "cat-dark-red"; break;
    case "academic query":
      return "cat-dark-red"; break;
    case "new studies":
      return "cat-yellowshade"; break;
    case "current studies":
      return "cat-yellowshade"; break;
    case "software":
      return "cat-greenhex"; break;
    case "online/virtual classes":
      return "cat-greenhex"; break;
    case "portal on hold":
      return "cat-yellow"; break;
    case "general account query":
      return "cat-yellow"; break;
    case "statement request":
      return "cat-greenhex"; break;
    default:
      return "cat-dark-red"; break;
  }
};

export const ColorRender = (props) => {
  let str = props ? props.toLowerCase() : props
  var replaced = str ? str.replace(/-/g, ' ') : str;
  switch (replaced) {
    case "other":
      return "bgSkyblue";
      break;
    case "studykits":
      return "bgGreenHex";
      break;
    case "complaint":
      return "bgBlue";
      break;
    case "assessments":
      return "bgRed";
      break;
    case "student portal":
      return "bgGreenHex";
      break;
    case "external-exam-query":
      return "bgDarkRed";
      break;
    case "external exam query":
      return "bgDarkRed";
      break;
    case "accounts":
      return "bgYellow";
      break;
    case "internal exam query":
      return "bgDarkRed";
      break;
    case "academic query":
      return "bgDarkRed";
      break;
    case "new studies":
      return "bgYellowShade";
      break;
    case "current studies":
      return "bgYellowShade";
      break;
    case "software":
      return "bgGreenHex";
      break;
    case "virtual classes":
      return "bgGreenHex";
      break;
    case "portal on hold":
      return "bgYellow";
      break;
    case "general account query":
      return "bgYellow";
      break;
    case "statement request":
      return "bgGreenHex";
      break;
    default:
      return "bgDarkRed";
      break;
  }
};

const depUi = ({ color, text }) => {
  return (
    <span className={"cat " + color}>
      <i className="fas fa-circle"></i> {text}
    </span>
  )
}

export const DepartmentRender = (props) => {
  let str = props.status ? props.status.toLowerCase() : props.status
  var replaced = str ? str.replace(/-/g, ' ') : str;
  switch (replaced) {
    case "administration":
      return (depUi({ color: "cat-greenhex", text: "Administration" })); break;
    case "academic":
      return (depUi({ color: "cat-blue", text: "Academic" })); break;
    case "student support":
      return (depUi({ color: "cat-skyblue", text: "Student Support" })); break;
    case "sales":
      return (depUi({ color: "cat-yellow", text: "Sales" })); break;
    case "finance":
      return (depUi({ color: "cat-dark-red", text: "Finance" })); break;
    default:
      return (depUi({ color: "cat-dark-red", text: str ? str : 'NA' })); break;
  }
};

export const RenderQuestionType = ({type}) =>{
  switch (type) {
    case "Multiple Choice Single Answer":
      return <span className="cat-yellow" title="Single Choice"><i className="fal fa-tasks"></i> Single Choice</span>;
    case "Multiple Choice Multiple Answer":
      return <span className="cat-greenhex" title="Multiple Choice"><i className="far fa-check-square"></i> Multiple Choice</span>;
    case "Match the Column":
      return <span className="cat-light-pink" title="Match The Column"><i className="far fa-line-columns"></i> Match The Column</span>;
    case "Short Answer":
      return <span className="cat-blue" title="Short Answer"><i className="far fa-comment-alt-lines"></i> Short Answer</span>;
    case "Long Answer":
      return <span className="cat-red" title="Long Answer"><i className="fal fa-align-left"></i> Long Answer</span>;
    case "Fill in the blank":
      return <span className="cat-dark-blue" title="Fill In The Blanks"><i className="far fa-keyboard"></i> Fill In The Blanks</span>;
    case "Order List":
      return <span className="cat-extra-blue" title="Order List"><i className="fas fa-sort-numeric-down"></i> Order List</span>;
    case "Choice Matrix":
      return <span className="cat-light-green" title="Choice Matrix"><i className="fal fa-table"></i> Choice Matrix</span>;
    case "Document Upload":
      return <span className="cat-purple" title="Document Upload"><i className="fal fa-file-upload"></i> Document Upload</span>;
    default:
      return <span className="cat-black" title={type}>{type}</span>;
  }
}

export const RenderQuestionLevel = ({level}) =>{  
  switch(level.toLowerCase()){
    case "easy":
      return <span className="cat cat-yellowshade" title="Easy"><i className="fas fa-circle"></i> Easy</span>;
    case "medium":
      return <span className="cat cat-blue" title="Medium"><i className="fas fa-circle"></i> Medium</span>;
    case "hard":
      return <span className="cat cat-red" title="Hard"><i className="fas fa-circle"></i> Hard</span>;
  }
}

const SpanUIRender = ({ color, text, icon }) => {  
  return {
      color,
      text,
      html: (<span title={text} className={`as-${color}-bg as-widget`}>{icon ? <i className={`fal ${icon}`}></i> : null} {text}</span>),
  }
}

export const RenderQuizResult = (data) => {
  if ((parseFloat(data) > 0 && parseFloat(data) < 1)) {
      data = "partiallycorrect"
  }
  if ((parseFloat(data) > 1)) {
      data = "correct"
  }
  switch (data ? data.toString().replace("-", "").toLowerCase() : "") {
      case "1":
          return SpanUIRender({ color: "green", text: "Correct"});
      case "correct":
          return SpanUIRender({ color: "green", text: "Correct"});
      case "0":
          return SpanUIRender({ color: "red", text: "Incorrrect"});
      case "incorrect":
          return SpanUIRender({ color: "red", text: "Incorrrect"});
      case "partiallycorrect":
          return SpanUIRender({ color: "blue", text: "Partially Correct"});
      case "document":
          return SpanUIRender({ color: "yellow", text: "Manually Marked"});
      default:
          return SpanUIRender({ color: "cat-black", text: "-"});
  }
};

export const IconUIRender = ({color, text, icon}) =>{
  return {
    color,
    text,
    html : (<span title={text} style={{color:color}}><i className={`fal ${icon}`}></i> {text}</span>),
    iconOnly : (<span style={{color:color}}><i className={`fal ${icon}`}></i></span>)
  }
}


export const RenderFileType = (data) =>{
  const type = data.toString().toLowerCase();
  switch(type){
    case ".png":
      return IconUIRender({color : "#00B7C3", text : "PNG", icon : "fa-file-image"})
    case ".pdf":
      return IconUIRender({color : "#F40F02", text : "PDF", icon : "fa-file-pdf"})
    case ".doc":
      return IconUIRender({color : "#2B579A", text : "DOC", icon : "fa-file-word"})
    case ".jpg":
      return IconUIRender({color : "#00B7C3", text : "JPG", icon : "fa-file-image"})
    case ".xlxs":
      return IconUIRender({color : "#217346", text : "XLXS", icon : "fa-file-excel"})
    case ".wmv":
      return IconUIRender({color : "#CA5010", text : "WMV", icon : "fa-file-video"})
    case ".zip":
      return IconUIRender({color : "#7A7574", text : "ZIP", icon : "fa-file-archive"})
    case ".rar":
      return IconUIRender({color : "#7A7574", text : "RAR", icon : "fa-file-archive"})
    case ".mp4":
      return IconUIRender({color : "#CA5010", text : "MP4", icon : "fa-file-video"})
    case ".xls":
      return IconUIRender({color : "#217346", text : "XLS", icon : "fa-file-excel"})
    case ".docx":
      return IconUIRender({color : "#2B579A", text : "DOCX", icon : "fa-file-word"})
    case ".csv":
      return IconUIRender({color : "#217346", text : "CSV", icon : "fa-file-excel"})
    case ".txt":
      return IconUIRender({color : "#4F6BED", text : "TXT", icon : "fa-file-alt"})
    case ".html":
      return IconUIRender({color : "#00AD56", text : "HTML", icon : "fa-file-code"})
    case ".vsdx":
      return IconUIRender({color : "#4F6BED", text : "VSDX", icon : "fa-file-alt"})
    case ".webp":
      return IconUIRender({color : "#00AD56", text : "WEBP", icon : "fa-file-code"})
    case ".ppt":
      return IconUIRender({color : "#B7472A", text : "PPT", icon : "fa-file-powerpoint"})
    case ".mp3":
      return IconUIRender({color : "#5C2E91", text : "PPT", icon : "fa-file-music"})
  
    default:
      return IconUIRender({color : "#4F6BED", text : type, icon : "fa-file"})
  }
}

export const RenderFeedAssessmentType = (data) =>{
  const type = data.toString().toLowerCase();
  switch(type){
    case "assignment":
      return SpanUIRender({color : "blue", text: "Assignment", icon : "fa-file"})
    case "internal exam":
      return SpanUIRender({color : "green", text: "Internal Exam", icon : "fa-file"})
    case "external exam":
      return SpanUIRender({color : "orange", text: "External Exam", icon : "fa-university"})
    case "test":
      return SpanUIRender({color : "red", text: "Test", icon : "fa-file"})
    case "poe-formative":
      return SpanUIRender({color : "blue", text: "PoE-Formative", icon : "fa-folder-tree"})
    case "poe-summative":
      return SpanUIRender({color : "green", text: "PoE-Summative", icon : "fa-folder-tree"})
    case "poe-informal activities":
      return SpanUIRender({color : "blue", text: "PoE-Informal Activities", icon : "fa-folder-tree"})
    case "survey":
      return SpanUIRender({color : "blue", text: "Survey", icon : "fa-file"})
    case "supplementary exam":
      return SpanUIRender({color : "blue", text: "Supplementary Exam", icon : "fa-file"})
    case "sick test":
      return SpanUIRender({color : "blue", text: "Sick Test", icon : "fa-file"})
    
    default:
      return SpanUIRender({ color: "cat-black", text: "NA", icon : "fa-file"});
  }
}

export const RenderFeedAssessmentStatus = (data) =>{
  const status = data.toString().toLowerCase();

  switch(status){
    case "open":
      return SpanUIRender({color : "blue", text: "Open"})
    case "draft":
      return SpanUIRender({color : "green", text: "Draft"})
    case "submitted":
      return SpanUIRender({color : "green", text: "Submitted"})
    case "closed-submitted":
      return SpanUIRender({color : "orange", text: "Closed-Submitted"})
    case "pending":
      return SpanUIRender({color : "orange", text: "Pending"})
    case "closed-marked":
      return SpanUIRender({color : "blue", text: "Closed-Marked"})
    case "closed not-submitted":
      return SpanUIRender({color : "red", text: "Closed Not-Submitted"})
    
    default:
      return SpanUIRender({ color: "cat-black", text: "NA"});
  }
}