import React, { createRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import TextareaAutosize from "react-autosize-textarea";
import FeedComments from "./FeedComments";
import FeedAttachments from "./FeedAttachments";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SocketConnection from "../../utils/SocketConnection";
import _ from "underscore";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";
import {
  API_BASE,
  BASE_URL,
  TABLE_DATE_TIME_FORMAT,
} from "../../utils/Constants";
import Str from "../common/Str";
import { getToken, removeUserSession } from "../../utils/Auth";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../assets/css/filePond.css";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "../../utils/defaultStyle";
import defaultMentionStyle from "../../utils/defaultMentionStyle";
import {
  classStatusWidget,
  classStatusWidgets,
  rollsWidget,
} from "./../common/Helper";
import UserHover from "./../common/UserHover";
import { Editor } from "react-draft-wysiwyg";
import "../../assets/js/rating.js";
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  Modifier,
  Entity,
  CompositeDecorator,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HtmlInputEditorShow from "../common/HtmlInputEditorShow";
import HtmlInputEditor from "../common/HtmlInputEditorReply";
import moment from "moment";
import $ from "jquery";
import Swal from "sweetalert2";
import {
  GetMentionedUserDetails,
  GetPostFeedMessagesPaginate,
  GetSupportTeamService,
  readCommentMessages,
} from "../../services/QualificationService";
import draftToHtml from "draftjs-to-html";
import renderHTML from "react-render-html";
import { setHoverUserData, setUser } from "../../store/actions";
import { ShowMoreHide } from "../common/ShowMoreHide";
import ReactDynamicModal from "react-draggable-resizable-modal";
import HtmlParser from "react-html-parser";
import htmlToDraft from "html-to-draftjs";
import ami_logo from "../../assets/images/ami-logo-icon.svg";
import {
  CampusClass,
  GetSubjectClasses,
  JoinBBBClass,
  SaveFeedbackRating,
} from "../../services/ClassService";
import { getRandomInt, twoDecimalRound } from "../../utils/commonFunction";
import { PopupContent } from "semantic-ui-react";
import { Popup } from "semantic-ui-react";
import { useMemo } from "react";
import { forwardRef } from "react";
import ThumbsUpIcon from '../../assets/images/Thumbsup.png'
import ThumbsDownIcon from '../../assets/images/ThumbsDown.png'
import RedHeartIcon from '../../assets/images/RedHeart.png'
import PoutingIcon from '../../assets/images/Pouting.png'
import OpenFaceIcon from '../../assets/images/OpenFace.png'
import GrinningIcon from '../../assets/images/GrinningFace.png'
import FrowningIcon from '../../assets/images/Frowning.png'
import UnknownStudent from "../../assets/images/ic_unknown_user.png"
import UnknownStudentStatus from "../../assets/images/ic_unknown_user_online_status.png"
import { RenderFeedAssessmentStatus, RenderFeedAssessmentType } from "../ticket/StatusRender.js";
import { Link, useParams } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";

registerPlugin(FilePondPluginImagePreview);

const FeedMessage = forwardRef((props, ref) => {
  const childFun = useRef(null);
  const {qid} = useParams();
  const user = useSelector((state) => state.user);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [chat, setChat] = useState(null);
  const commentTxt = createRef();
  const btnAttachment = createRef();
  const [isOpen, setIsOpen] = useState(false);
  const filePondRef = createRef();
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [showFilePond, setShowFilePond] = useState(false);
  const [removeFile, setRemoveFile] = useState(null);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [users, setUsers] = useState("");
  const [copyText, setCopyText] = useState(0);
  const [isImportant, setIsImportant] = useState(false);
  const [isImportantReply, setIsImportantReply] = useState(false);
  const [showcursure, setShowCurser] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [showHideCOmm, setShowHideCOmm] = useState("");
  const [cssObj, setCssObj] = useState({ display: "none" });
  const [hoverUser, setHoverUser] = useState({});
  const [mentionedUserData, setMentionedUserData] = useState([]);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [iframeData, setIframeData] = useState();
  const [isMention, setIsMention] = useState(false);
  const [row, setClasses] = useState([]);
  const [popupUpdate, setPopupUpdate] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [team, setTeam] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [assessment, setAssessment] = useState();
  const [notification, setNotification] = useState();
  const [showReplyTo, setShowReplyTo] = useState(false);
  const [replyToData, setReplyToData] = useState();
  const isWithHeldStatus = localStorage.getItem("resultWithHeld");
  // console.log(props.teamObj,"props.teamObj")

  const InitalRender = useMemo(() => (data) => {
    if (data != null && data != undefined && data) {
      let tempArr = data.split(" ")
      let initials = ''
      tempArr.map(i => {
        if (i) {
          initials = initials + i[0].toUpperCase();
        }
      })
      initials = initials.length > 2 ? initials.slice(0, 2) : initials
      return initials
    }
    return 'NA'
  
  },[])

  const originalValues = useCallback((s) => {
    try {
      return decodeURIComponent(escape(s));
    } catch (error) {
      return s;
    }
  },[])

  const HandleSpan = useCallback((props) => {
    return (
      <span style={styles.handle} data-offset-key={props.offsetKey}>
        {props.children}
      </span>
    );
  },[]);

  
  useEffect(() => {
    let finalObj = props.teamObj.find((item) => {
      return item.id == props.feed.admin_id;
    });
    setUserRole(
      finalObj
        ? finalObj.role
        : props.feed.admin_id === null
        ? "student"
        : props?.feed?.adminuser?.job_title
    );
    // let userTitle = Object.keys(teamArr[0]).find((item)=>{
    // if(props.feed.admin_id != null){
    //   if(teamArr[0][item].id == props.feed.admin_id) {
    //     console.log("conssosos", item)
    //     setUserRole(item)
    //   }
    // } else if(props.feed.admin_id == null){
    //   setUserRole("student")
    // } else {
    //   setUserRole(props.feed.adminuser.job_title)
    // }
    // })
  }, [props.feed]);

  function handleStrategy(contentBlock, callback, contentState) {
    findWithRegex(HANDLE_REGEX, contentBlock, callback);
  }

  const compositeDecorator = new CompositeDecorator([
    { strategy: handleStrategy, component: HandleSpan },
  ]);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(compositeDecorator)
  );
  const compositeDecorator2 = new CompositeDecorator([
    { strategy: handleStrategy, component: HandleSpan },
  ]);
  const [editorState2, setEditorState2] = React.useState(() =>
    EditorState.createEmpty()
  );
  const HANDLE_REGEX = /\@[\w]+/g;
  const styles = {
    editor: {
      border: "1px solid #ddd",
      cursor: "text",
      fontSize: 16,
      minHeight: "40px",
      padding: 10,
      background: "#000",
    },
    handle: {
      color: "rgba(98, 177, 254, 1.0)",
      direction: "ltr",
      unicodeBidi: "bidi-override",
    },
  };

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  const copyEmailText = (text) => {
    setCopyText(1);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setCopyText(0);
    }, 1000);
  };

  const joinVirtualClassGlobal = (id) => {
    JoinBBBClass({ id })
      .then((res) => {
        if (res.data.error == 0) {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: res.data.title,
            text: res.message,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.open(res.data.url, "_blank");
              // GetPostFeedMessagesPaginate({ room_id: props.subjectId, user_id: user.id }, props.page)
              // .then(data => {
              //   let ClassObject = data.data.data.map((item,index)=>{
              //         if(item.ClassArray != null) {
              //           setClasses(item?.ClassArray)
              //         }
              //   })
              // })
              // .catch(err => {
              //   console.log(err)
              // })
              setClasses(res.data.class);
              setPopupUpdate(id + getRandomInt(1));
              // let item = res.data.class;
              // var foundIndex = row.findIndex((x) => x.id == item.id);
              // row[foundIndex] = item;

            }
          });
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: res.data.title,
            text: res.data.message,
            showConfirmButton: true,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Unknown Error.",
        }).then((result) => {});
      });
  };

  const joinCampusClassGlobal = (id) => {
    let action = "enter";
    CampusClass({ id, action })
      .then((res) => {
        if (res.data.error == 0) {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: res.data.title,
            text: res.data.message,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              // GetPostFeedMessagesPaginate({ room_id: props.subjectId, user_id: user.id }, props.page)
              // .then(data => {
              //   let ClassObject = data.data.data.map((item,index)=>{
              //         if(item.ClassArray != null) {
              //           setClasses(item?.ClassArray)
              //         }
              //   })
              // })
              // .catch(err => {
              //   console.log(err)

              // })
              setClasses(res.data.class);
              setPopupUpdate(id + getRandomInt(2));
              // let item = res.data.class;
              // var foundIndex = row.findIndex((x) => x.id == item.id);
              // row[foundIndex] = item;
            }
          });
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: res.data.title,
            text: res.data.message,
            showConfirmButton: true,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Unknown Error.",
        }).then((result) => {});
      });
  };

  const exitCampusClassGlobal = (id) => {
    let action = "exit";
    CampusClass({ id, action })
      .then((res) => {
        if (res.data.error == 0) {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: res.data.title,
            text: res.data.message,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              // GetPostFeedMessagesPaginate({ room_id: props.subjectId, user_id: user.id }, props.page)
              // .then(data => {
              //   let ClassObject = data.data.data.map((item,index)=>{
              //         if(item.ClassArray != null) {
              //           setClasses(item?.ClassArray)
              //         }
              //   })
              // })
              // .catch(err => {
              //   console.log(err)
              // })
              setClasses(res.data.class);
              setPopupUpdate(id + getRandomInt(3));
              // let item = res.data.class;
              // var foundIndex = row.findIndex((x) => x.id == item.id);
              // row[foundIndex] = item;
            }
          });
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: res.data.title,
            text: res.data.message,
            showConfirmButton: true,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Unknown Error.",
        }).then((result) => {});
      });
  };
  const submitRatingGlobal = (id) => {
    let student_rating = $("#class_rating_" + id).val();
    let student_comment = $("#class_comment_" + id).val();
    SaveFeedbackRating({ student_rating, student_comment, id })
      .then((res) => {
        if (res.data.error == 0) {
          setIsOpen(!isOpen);
          Swal.close();
          Swal.fire({
            icon: "success",
            title: res.data.title,
            text: res.data.message,
            showConfirmButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              GetPostFeedMessagesPaginate({ room_id: props.subjectId, user_id: user.id }, props.page)
              .then(data => {
                let ClassObject = data.data.data.map((item,index)=>{
                      if(item.ClassArray != null) {
                        setClasses(item?.ClassArray)
                      }
                    })
                  })
                  .catch(err => {
                    console.log(err)
                  })
                  
              
              // setClasses(res.data.class);
              setPopupUpdate(id + getRandomInt(4));
              // let item = res.data.class;
              // var foundIndex = row.findIndex((x) => x.id == item.id);
              // row[foundIndex] = item;
            }
          });
        } else {
          setIsOpen(!isOpen);
          Swal.close();
          Swal.fire({
            icon: "error",
            title: res.data.title,
            text: res.data.message,
            showConfirmButton: true,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Unknown Error.",
        }).then((result) => {});
      });
  };

  useEffect(() => {
    $(document).on("click", ".class-list-row", function () {      
      const t = setTimeout(() => {
        $("input.rating[type=number], div.rating").each(function () {
          $(this).rating();
        });
      }, 100);

      return () => {clearTimeout(t)}
    });
  
    $(document).ready(function () {
      $("#divtoshow").hover(
        function (a) {
          $("#divtoshow")
            .css({
              top: -5 + a.clientY,
              left: -10 + a.clientX,
              position: "absolute",
              visibility: "visible",
              opacity: 1,
              "z-index": 999,
            })
            .show();
        },
        function () {
          $("#divtoshow")
            .css({
              position: "absolute",
              visibility: "hidden",
              opacity: 0,
              "z-index": 0,
            })
            .hide();
        }
      );
      $(`body`).on("click", ".wysiwyg-mention", function (a) {
        a.preventDefault();
        return false;
      });
      $(`.f-m-cont-${props.ind} .wysiwyg-mention`).hover(
        async function (a) {
          a.preventDefault();
          let thi = $(this);
          let user_id = thi.attr("href");
          if (!user_id || user_id == null || user_id == "" || user_id == "#" || user_id == "everyone-users")
            return;
          await GetMentionedUserDetails(user_id)
            .then((data) => {
              dispatch(
                setHoverUserData(
                  data && data.data && data.data.data ? data.data.data : {}
                )
              );
              $("#divtoshow")
                .css({
                  top: -5 + a.clientY,
                  left: -10 + a.clientX,
                  position: "absolute",
                  visibility: "visible",
                  opacity: 1,
                  "z-index": 999,
                })
                .show();
            })
            .catch((error) => {
              console.log("errorrrrrrr===> ", error);
              $("#divtoshow").hide();
            });
        },
        function () {
          // dispatch(setHoverUserData({}));
          $("#divtoshow").hide();
        }
      );
    });
  }, []);

  useEffect(() => {
    setIsMention(
      props.feed.message?.includes(`@${user.fullname}`) ? true : false
    );
  }, [props.feed.message]);

  useEffect(() => {
    setClasses(props?.feed?.ClassArray != null && props.feed.ClassArray);
    setAssessment(props?.feed?.assessment != null && props.feed.assessment);
    setNotification(props?.feed?.notification != null && props.feed.notification);
  }, [props.subjectId, popupUpdate, chat]);

  // useEffect(() => {
  //   $(document).ready(function () {
  //     // $(document).on("click", ".join-virtual-classes-list-view", function (e) {
  //     //   var id = $(this).attr("data-id");
  //     //   joinVirtualClassGlobal(id);
  //     // });
  //     // $(document).on("click", ".join-campus-classes-list-view", function (e) {
  //     //   var id = $(this).attr("data-id");
  //     //   joinCampusClassGlobal(id);
  //     // });
  //     // $(document).on("click", ".exit-campus-classes-list-view", function (e) {
  //     //   var id = $(this).attr("data-id");
  //     //   exitCampusClassGlobal(id);
  //     // });
  //     // $(document).on("click", ".submit-rating-global-list-view", function (e) {
  //     //   var id = $(this).attr("data-id");
  //     //   submitRatingGlobal(id);
  //     // });
  //   });
  // }, []);

  useEffect(() => {
    if (props.taggingUsers && props.taggingUsers.length > 0) {
      let usersArr = [...props.taggingUsers];
      usersArr.map((i) => {
        i.text = i.display;
        i.value = i.display;
        i.url = i.id + "-" + i.type;
      });
      usersArr.push({text:"everyone", value:"everyone", url:"everyone-users", id:"everyone",display:"everyone", type:"users"})
      setUsers([...usersArr]);
    }
  }, []);

  useEffect(() => {
    let tempBlock = convertToRaw(editorState2.getCurrentContent());
    if (
      (tempBlock && tempBlock.blocks && tempBlock.blocks.length > 1) ||
      (tempBlock && tempBlock.blocks && tempBlock.blocks[0].text != "")
    ) {
      setComment("yes");
    } else {
      setComment("");
    }
  }, [editorState2]);

  useEffect(() => {
    if (props.feed.comments) {
      setComments(props.feed.comments);
    }
    if (!props.feed.class_id) {
      let isBlock = isJsonString(props?.feed?.message ? props?.feed?.message : "");
      if (isBlock && isNaN(props?.feed?.message)) {
        const blocksFromHTML = convertFromRaw(
          JSON.parse(props?.feed?.message ? props?.feed?.message : "")
        );
        setEditorState(EditorState.createWithContent(blocksFromHTML));
      } else {
        const blocksFromHtml = htmlToDraft(props.feed.message);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      }

      if (!props.feed.readMessages?.id && props.feed?.user?.id !== user.id) {
        setIsNew(true);
        readComment(props.feed.id, null);
      } else if (!props.feed.readMessages?.id) {
        readComment(props.feed.id, null);
      }
    }
  }, []);

  useEffect(() => {
    if(props.feed.type == 1 && props.feed.notification != null && props.feed.notification_id>0){
      setIsImportant(props.feed.notification.ish_importanceActive == 1 ? true : false);      
    }else{
      setIsImportant(props.feed.important == 1 ? true : false);
    }
  }, [props.feed.important]);

  const readComment = (msg, comment) => {
    readCommentMessages({ message_id: msg, comment_id: comment }).then(
      (data) => {
        console.log("the data of read response for subject is ", data);
      }
    );
  };

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (chat) {
      chat.on("message", (message) => {
        // console.log("message socket-----> ", message);
        if (
          message &&
          message.message_type &&
          message.message_type == "edited"
        ) {
          updateEdited(message);
        } else {
          let tempArr = comments;
          tempArr.push(message);
          setComments(shuffleArray([...tempArr]));
        }
      });
      chat.on("likeDislike", (message) => {
        let arr = comments;
        arr.map((e, i) => {
          if (e.id === message.data.comment_id) {
            arr[i].likes = message.likes;
            arr[i].dislikes = message.dislikes;
            arr[i].love = message.love;
            arr[i].laugh = message.laugh;
            arr[i].surprised = message.surprised;
            arr[i].sad = message.sad;
            arr[i].angry = message.angry;
          }
          if (arr.length - 1 === i) setComments((pre) => [...arr]);
        });
      });
      chat.on("messageDelete", (message) => {
        // console.log("messageDelete socket-----> ", message);
        let arr = comments;
        arr.map((e, i) => {
          if (e.id === message.comment_id) {
            arr.splice(i, 1);
          }
        });
        setComments((pre) => [...arr]);
      });
    }
  }, [chat]);

  const shuffleArray = (arr) => {
    arr.map((e, i) => {
      if (!e.user) arr[i].user = e.adminuser;
    });
    return arr;
  };

  const updateEdited = (data) => {
    let arr = comments;
    arr.map((e, i) => {
      if (e.id === data.id) {
        e.comment = data.comment;
        e.important = data.important;
        e.attachments = data.attachments;
      }
    });
    setComments((pre) => [...arr]);
  };

  const chatSubscription = async () => {
    if (props.feed.id) {
      const messageID = props.feed.id;
      const roomID = props.feed.room_id;

      //if (subscription) subscription.close();

      let subscription = await SocketConnection.subscribe(
        `adminmessage:${messageID}-${roomID}`,
        "test"
      );

      await setChat(subscription);
    }
  };

  useEffect(() => {
    chatSubscription();
  }, [props.feed.id]);

  useEffect(() => {
    if (chat) {
      chat.close();
    }
  }, []);

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const removeTagsFromStr = (str) => {
    str = str.replace(/<[^>]+>/g, " ");
    str = str.replace(/&amp;/g, "&");
    if (str === null || str === "") return false;
    else str = str.toString();

    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const getTextContentLength = (text, charLen = 100) => {
    text = removeTagsFromStr(text);
    // For Text that is shorter than desired length
    if (text.length <= charLen) return text;

    // If text is longer than desired length & showMore is true
    if (text.length > charLen && showMore) {
      return (
        <>
          <p>
            {text}&nbsp;
            <a
              href
              className="class-schedule h5 p"
              onClick={() => setShowMore(false)}
            >
              Show less
            </a>
          </p>
        </>
      );
    }
    // If text is longer than desired length & showMore is false
    if (text.length > charLen) {
      return (
        <>
          <p>
            {text.slice(0, charLen)}&nbsp;
            <a
              href
              className="class-schedule h5"
              onClick={() => setShowMore(true)}
            >
              Show more
            </a>
          </p>
        </>
      );
    }
  };

  const sendComment = () => {
    const messageValue = draftToHtml(
      convertToRaw(editorState2.getCurrentContent())
    );

    chat.emit("message", {
      message: messageValue,
      feed: props.feed.id,
      type: "comment",
      files: attachments,
      sizes: sizes,
      taggedUsers,
      important: isImportantReply,
      user_id: user.id,
      role: "student",
      reply_comment : replyToData!=undefined && replyToData.id > 0 ? replyToData.id : 0
    });

    setEditorState2(
      EditorState.push(editorState2, ContentState.createFromText(""))
    );
    setComment("");
    setAttachments([]);
    setSizes([]);
    setFiles([]);
    setShowFilePond(false);
    handleRemoveReply();
    setTimeout(() => {
      props.setOpenReplyId("");
      // setStateEditorShow(false)
      // setStateMainThreadEditorShow(false)
    }, 1000);
  };

  const likeDislikeHandle = (data) => {
    data.user_id = user.id;
    data.role = "student";
    chat.emit("likeDislike", data);
  };

  const deleteHandle = (data) => {
    data.user_id = user.id;
    data.role = "student";
    chat.emit("messageDelete", data);
  };

  const editHandle = (
    messageValue,
    comment_id,
    tempImportant,
    attachments,
    sizes,
    deletedFiles,
    message_id
  ) => {
    chat.emit("message", {
      message: messageValue,
      sizes: sizes,
      feed: props.feed.id,
      type: "comment",
      message_type: "edited",
      comment_id: comment_id,
      message_id: message_id,
      files: attachments,
      deletedFiles: deletedFiles,
      taggedUsers,
      important: tempImportant,
      user_id: user.id,
      role: "student",
    });
  };

  const handleAttachment = () => {
    btnAttachment.current.click();
  };

  const handleUploadedFiles = (response) => {
    let newObj = {};
    let blocks = isJsonString(response);
    if (blocks) {
      newObj = JSON.parse(response);
      setAttachments((attachments) => [...attachments, newObj.fullDocFilePath]);
      setSizes((pre) => [...pre, newObj.size]);
    }
  };

  const handleFiles = (event) => {
    const ufiles = event.target.files;
    if (ufiles.length > 0) {
      for (var i = 0; i < ufiles.length; i++) {
        if (ufiles[i]) {
          if (filePondRef.current) {
            filePondRef.current
              .addFile(ufiles[i])
              .then((file) => {})
              .catch((error) => console.log(error));
          }
        }
      }
    }
  };

  const handleUserTagged = (user) => {
    const extendedList = taggedUsers;
    setTaggedUsers([...extendedList, user]);
  };

  const handleDragEnter = (event) => {
    setShowFilePond(true);
  };

  const attachmentsArrUpdate = (file) => {
    let tempAttach = attachments;
    for (var i = 0; i < tempAttach.length; i++) {
      if (tempAttach[i].includes(file.split(".")[0])) {
        tempAttach.splice(i, 1);
        break;
      }
    }
    // console.log('tempAttach value would be ', tempAttach)
    if (tempAttach.length < 1) {
      setTimeout(() => {
        setShowFilePond(false);
        setShowCurser(!showcursure);
      }, 500);
    }
  };

  const showHideReply = () => {
    props.setOpenReplyId(props.feed.id);
    setAttachments([])
  };

  useEffect(() => {
    $(".post-by").on("click", function (e) {
      if (e.target !== this) return;
      props.resetEditor(true);
    });
  }, []);

  const subjectCon = () => {
    if (props.feed.id != props.showHidePostEdit) {
      props.resetEditor(true);
    }
  };

  const deleteConfirm = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete comment!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteHandle({
          comment_id: null,
          message_type: "deleted",
          message_id: id,
          table_type: "message",
          deletedFiles: [],
        });
      }
    });
  };

  const renderActionList = useCallback((usersArr) => {
    return usersArr.map((i, ind) => {
      if (!i.user) {
        i.user = i.adminuser;
        i.user_id = i?.adminuser?.UserID ? i?.adminuser?.UserID : "00";
        i.userType = "admin";
      }
      return (
        <li key={ind}>
          <div className="user-drop-down-img online-status card-left assigned-title-blk">
            {i?.user ? 
            <>
              {i?.user?.photo == null ? (
                i?.user?.fullname ? InitalRender(i?.user?.fullname) : ""
              ) : (
                <img src={i?.user?.photo} />
              )}            
              <span
                className={`portal-status-icon ${
                  i?.user?.activity_status ? 
                  (i.user.activity_status?.toLowerCase() == "online"
                    ? "online-u"
                    : i.user.activity_status?.toLowerCase() == "donotdisturb"
                    ? "donotdisturb-u"
                    : "offline-u") : "offline-u"
                }`}
              >
                {" "}
              </span>
            </>
            : <>
            <img src={UnknownStudent} alt="unknown" />
            <span className="portal-status-icon border-0">
              <img src={UnknownStudentStatus} alt="unknown" />    
            </span>
            </>
            }
            <UserHover
              // directionCls={"popup-top"}
              user={i.user}
              hideEmail={true}
              online={`portal-status-icon ${
                i.user?.activity_status?.toLowerCase() == "online"
                  ? "online-u"
                  : i.user?.activity_status?.toLowerCase() == "donotdisturb"
                  ? "donotdisturb-u"
                  : "offline-u"
              }`}
            />
            {/* <div className="assigned-title-info">
              <article>
                <figure className="bgBlue">
                  <div className="user-drop-down-img">
                    {i.user.photo ? <img src={i.user.photo} onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.closest('.bgBlue').append(InitalRender(originalValues(i.user.fullname, 23)));
                      currentTarget.remove();
                    }} /> : InitalRender(originalValues(i.user.fullname))}
                    <span className={`portal-status-icon ${i.user.activity_status?.toLowerCase() == 'online' ? 'online-u' : i.user.activity_status?.toLowerCase() == 'donotdisturb' ? 'donotdisturb-u' : 'offline-u'}`}></span>
                  </div>
                </figure>
                <figcaption>
                  <h4>{originalValues(i.user.fullname)}</h4>
                  <p>
                    <i className="fal fa-envelope"></i>
                    <a href="#!" className="hover-color-blue" onClick={() => copyEmailText(i.user.email)} >{i.user.email}</a>
                    {copyText ? (<i className="fas fa-check-circle cat-greenhex"></i>) : (i.user.email && <i className="fal fa-copy hover-color-blue" onClick={() => copyEmailText(i.user.email)}></i>)}
                  </p>
                </figcaption>
              </article>
            </div> */}
          </div>
          <div className="info-drop">
            <span title={i?.user?.first_name ? i?.user?.first_name : "Unknown User"}>{i?.user?.first_name ? i?.user?.first_name : "Unknown User"}</span>
            {i?.user ? 
            <a
              href="#!"
              title={`${
                isLiked(i.user.id, props.feed.likes ? props.feed.likes : [])
                  ? "Like"
                  : isDisliked(
                      i.user.id,
                      props.feed.dislikes ? props.feed.dislikes : []
                    )
                  ? "Dislike"
                  : isLoved(i.user.id, props.feed.love ? props.feed.love : [])
                  ? "Love"
                  : isLaughed(
                      i.user.id,
                      props.feed.laugh ? props.feed.laugh : []
                    )
                  ? "Laugh"
                  : isSurprised(
                      i.user.id,
                      props.feed.surprised ? props.feed.surprised : []
                    )
                  ? "Surprised"
                  : isSad(i.user.id, props.feed.sad ? props.feed.sad : [])
                  ? "Sad"
                  : isAngry(i.user.id, props.feed.angry ? props.feed.angry : [])
                  ? "Angry"
                  : "NA"
              }`}
            >
              {
                  isLiked(i.user.id, props.feed.likes ? props.feed.likes : [])
                    ? <img className="chat-reaction-icon" src={ThumbsUpIcon} alt="Like" />
                    : isDisliked(
                        i.user.id,
                        props.feed.dislikes ? props.feed.dislikes : []
                      )
                    ? <img className="chat-reaction-icon" src={ThumbsDownIcon} alt="Dislike" />
                    : isLoved(i.user.id, props.feed.love ? props.feed.love : [])
                    ? <img className="chat-reaction-icon" src={RedHeartIcon} alt="Love" />
                    : isLaughed(
                        i.user.id,
                        props.feed.laugh ? props.feed.laugh : []
                      )
                    ? <img className="chat-reaction-icon" src={GrinningIcon} alt="Laugh" />
                    : isSurprised(
                        i.user.id,
                        props.feed.surprised ? props.feed.surprised : []
                      )
                    ? <img className="chat-reaction-icon" src={OpenFaceIcon} alt="Surprised" />
                    : isSad(i.user.id, props.feed.sad ? props.feed.sad : [])
                    ? <img className="chat-reaction-icon" src={FrowningIcon} alt="Sad" />
                    : isAngry(
                        i.user.id,
                        props.feed.angry ? props.feed.angry : []
                      )
                    ? <img className="chat-reaction-icon" src={PoutingIcon} alt="Angry" />
                    : null
                }                            
            </a>
            :null
            }
          </div>
        </li>
      );
    });
  },[]);

  const isLiked = (login_user, likeArr) => {
    if (likeArr.length) {
      for (var i = 0; i < likeArr.length; i++) {
        if (likeArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isDisliked = (login_user, likeArr) => {
    if (likeArr.length) {
      for (var i = 0; i < likeArr.length; i++) {
        if (likeArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isLoved = (login_user, loveArr) => {
    if (loveArr.length) {
      for (var i = 0; i < loveArr.length; i++) {
        if (loveArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };
  const isLaughed = (login_user, laughArr) => {
    if (laughArr.length) {
      for (var i = 0; i < laughArr.length; i++) {
        if (laughArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };
  const isSurprised = (login_user, surpriseArr) => {
    if (surpriseArr.length) {
      for (var i = 0; i < surpriseArr.length; i++) {
        if (surpriseArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isSad = (login_user, sadArr) => {
    if (sadArr.length) {
      for (var i = 0; i < sadArr.length; i++) {
        if (sadArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isAngry = (login_user, angryArr) => {
    if (angryArr.length) {
      for (var i = 0; i < angryArr.length; i++) {
        if (angryArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const importantHandlePost = (postData, value) => {
    // console.log('data in the other part is ', postData, value)
    props.updatePostImportant(postData, value);
    // let tempArr = importantArr[0]
    // tempArr[postData.id] = value
    // setImportantArr(pre => [tempArr])

    // let tempData = data
    // tempData.map(i => {
    //   if (i.id == postData.id) {
    //     i.important = value
    //   }
    // })
    // globalIconHandle(tempData)

    // setData(tempData)
  };

  const postEditedHandle = (
    messageValue,
    allData_id,
    attachments,
    sizes,
    subject
  ) => {
    props.sendClick(messageValue, allData_id, attachments, sizes, subject);
  };

  const closeModal = () => {
    setOpenModal(false);
    setIframeData("");
  };
  const editorRef = useRef(null);
  const [editorShow, setStateEditorShow] = useState(false);
  const [editorMainThreadShow, setStateMainThreadEditorShow] = useState(false);

  useImperativeHandle(ref, () =>({
    handleClickOutside(event){
      // console.log('the value of event.target is here ', event.target)
      if (
        editorRef.current &&
        !editorRef.current.contains(event.target) &&
        event.target.classList.contains("rdw-embedded-modal-btn") != true &&
        event.target.classList.contains("rdw-editor-toolbar") != true &&
        event.target.classList.contains("rdw-option-wrapper") != true &&
        event.target.classList.contains("rdw-editor-main") != true &&
        event.target.classList.contains("rdw-suggestion-option") != true &&
        event.target.classList.contains("edit_main_thread") != true &&
        event.target.classList.contains("public-DraftStyleDefault-block") !=
          true &&
        event.target.classList.contains("post-input-content") != true &&
        event.target.classList.contains("rdw-embedded-modal-link-input") !=
          true &&
        event.target.classList.contains("hold-show") != true &&
        event.target.classList.contains("swal2-cancel") != true &&
        event.target.classList.contains("swal2-confirm") != true
        // && event.target.classList.contains('attach-btn') != true
        // && event.target.classList.contains('important-btn') != true
        // && event.target.classList.contains('send-btn') != true
      ) {
        setStateEditorShow(false);
        setAttachments([])
        setStateMainThreadEditorShow(false);
        props.setOpenReplyId("");
      } else if (editorRef.current && editorRef.current.contains(event.target)) {
        setStateEditorShow(true);
      }
    }
  }))

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  const editPost = (item) => {
    // console.log('the editable comment is ', item)
    const t = setTimeout(() => {
      props.setShowHidePostEdit(item.id);
      setStateMainThreadEditorShow(true);
    }, 200);

    return () =>{clearTimeout(t)}
  };

  var isSystemBot = props.feed.ClassArray != null && props.feed.ClassArray ? true : false;
  var isAssessment = (props.feed.assessment != null && props.feed.assessment && props.feed.assessment_id>0) ? true : false;
  var isNotification = (props.feed.notification != null && props.feed.notification && props.feed.notification_id>0) ? true : false;

  var currentTime = moment().unix();
  var sst = moment(row.class_from, ["h:mm A"]).format("HH:mm:ss");
  var eet = moment(row.class_to, ["h:mm A"]).format("HH:mm:ss");
  var st = moment(row.class_date + " " + sst);
  var et = moment(row.class_date + " " + eet);
  var start_time = st.unix();
  var end_time = et.unix();
  var start_time_live = moment(st).subtract(15, "minutes").unix();
  var returnValue;
  var returnValue1;
  var star = "";
  var notStar = "";

  var remainingRating = 5 - row.student_rating;
  for (let index = 1; index <= row.student_rating; index++) {
    star += `<i class="fas fa-star" key={row.id}></i>`;
  }
  for (let index = 1; index <= remainingRating; index++) {
    notStar += `<i class="fal fa-star" key={row.id}></i>`;
  }
  row.student_rating > 0
    ? (returnValue = (
        <div className="col-12 meeting-rating-start-col m-0" key={row.id}>
          <div className="meeting-rating-start d-flex ">
            <span className="stars-system-bot">
              {ReactHtmlParser(star)}
              {ReactHtmlParser(notStar)}
            </span>
          </div>
        </div>
      ))
    : (returnValue = (
        <div className="col-12 meeting-rating-start-col" key={row.id}>
          <div className="meeting-rating-start d-flex">
            <input
              type="number"
              className="rating"
              id={`class_rating_${row.id}`}
            />
            <span className="ml-2">How Was Your Class ?</span>
          </div>
        </div>
      ));
  row.student_rating > 0
    ? (returnValue1 = (
        <div className="col-12 meeting-leave-comment-col m-0" key={row.id}>
          <span> {row.student_comment?.trim()}</span>{" "}
        </div>
      ))
    : (returnValue1 = (
        <div className="col-12 meeting-leave-comment-col" key={row.id}>
          <div className="meeting-leave-comment">
            <textarea
              rows="2"
              className="form-control"
              placeholder="leave a comment"
              id={`class_comment_${row.id}`}
            ></textarea>
            <a
              className="btn btn-primary submit-rating-global-list-view w-100"
              data-id={`${row.id}`}
              onClick={() => submitRatingGlobal(row?.id)}
            >
              Submit Feedback
            </a>
          </div>
        </div>
      ));

  const checkStatusAndRenderColor = (status,type) => {
    let str = status ? status.toLowerCase() : status;
    // console.log("statussssssss-----",str);
    if(type === "img"){
      switch (str) {
        case "past":
          return "as-red-bg-status";
        case "upcoming":
          return "as-orange-bg-status";
        case "live":
          return "as-green-bg-status";
        case "cancelled":
          return "as-red-bg-status-cancelled";
        default:
          return "";
      }
    } else {
      switch (str) {
        case "past":
          return "border-past";
        case "upcoming":
          return "border-upcoming";
        case "live":
          return "border-live";
        case "cancelled":
          return "border-cancelled";
        default:
          return "";
      }
    }
  }
  const rendercolorforstatus = (status,type) => {
    let str = status ? status.toLowerCase() : status;
      switch (str) {
        case "past":
          return "red";
        case "upcoming":
          return "orange";
        case "live":
          return "green";
        case "cancelled":
          return "red-cancelled";
        default:
          return "";
      }
  }

  const RenderMarkingCountdown = (marking_deadline) =>{    
    let countTimer =  Date.now() + (Date.parse(moment.unix(marking_deadline).format("DD MMM YYYY HH:mm a")) - Date.now());
    if (Date.now() > countTimer) {
      countTimer = 0;
    }
    const Renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        return <span>{zeroPad(0)}d:{zeroPad(0)}h:{zeroPad(0)}m</span>
      } else {
        //Render a countdown
        return <span>{zeroPad(days)}d:{zeroPad(hours)}h:{zeroPad(minutes)}m</span>
              
      }
    };

    return(
      countTimer ?
      <Countdown
        date={countTimer}
        renderer={Renderer}
        zeroPadTime='2'
      >
      </Countdown>
      : <Renderer completed={true} />
    )
  }

  const handleShowReplyEditor = (replyMsg) =>{    
    setShowReplyTo(true);
    props.setOpenReplyId(props.feed.id);
    setReplyToData(replyMsg)
    setStateEditorShow(true);
  }

  const handleRemoveReply = () =>{
    setShowReplyTo(false);    
    setReplyToData()
  }

  return (
    <>
      {props.showDate === true ? (
        <div
          onClick={() => {
            subjectCon(props.feed.id);
          }}
          className="or-line-post-ps"
        >
          <div className="or-line-post">
            <span className="post-by-time">
              {moment(props.feed.created_at, "Do MMMM YYYY, h:mm:ss a").format("dddd" + ", " + Str.TABLE_DATE_FORMAT)}
            </span>
          </div>
        </div>
      ) : null}
        <div
          className={`post-by user-profile f-m-cont-main f-m-cont-${
            props.ind
          } ${props.islastIndex ? " lastIndex " : ""}`}
        >
          <div className="post-by-left assigned-title-blk">
            <div className="user-drop-down-img desktop-user-img">
              {!isSystemBot && !isAssessment && !isNotification &&
              ((props.feed && props.feed.user && props.feed.user.photo) ||
                (props.feed &&
                  props.feed.adminuser &&
                  props.feed.adminuser.photo)) ? (
                <span className="rm-err h-100">
                  <img
                    src={
                      props.feed.user
                        ? props.feed.user.photo
                        : props.feed.adminuser.photo
                    }
                    alt={InitalRender(
                      originalValues(
                        props.feed.user
                          ? props.feed.user.fullname
                          : props.feed.adminuser.fullname
                      )
                    )}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget
                        .closest(".rm-err")
                        .append(
                          InitalRender(
                            originalValues(
                              props.feed.user
                                ? props.feed.user.fullname
                                : props.feed.adminuser.fullname,
                              23
                            )
                          )
                        );
                      currentTarget.remove();
                    }}
                  />
                </span>
              ) : isSystemBot ? (
                <span className="rm-err h-100">
                  <div className="system-bot-inner-L">
                    <figure className={`system-bot-figure-${rendercolorforstatus(row.nestedClassStaus)}`}>
                      {/* <img src={ami_logo} className={checkStatusAndRenderColor(row?.nestedClassStaus,"img")}></img> */}
                      <i className="fal fa-calendar"></i>
                    </figure>
                  </div>
                </span>
              ) : isAssessment ? (
                <i className="fal fa-ballot-check"></i>
              ) : isNotification ? (
                <span className="rm-err h-100">
                  <div className="system-bot-inner-L">
                    <figure className={`system-notification ${notification?.ish_importanceActive === 1 ? " important " : ""}`}>
                      <i className="fal fa-bell-exclamation"></i>                
                    </figure>
                  </div>
                </span>
              )
              : (
                props.feed.adminuser || props.feed.user ? 
                  <>
                    {InitalRender(
                      originalValues(
                        props.feed.user
                          ? props.feed.user.fullname
                          : props.feed.adminuser ? props.feed.adminuser.fullname : "Unknown User"
                      )
                    )}
                  </>
                : <img src={UnknownStudent} alt="Unknown User" />                
              )}
            </div>
            {(!isSystemBot && !isAssessment && !isNotification) ? (
              <>
                {props.feed.user || props.feed.adminuser ? 
                  <div
                    className={`post-by-status desktop-status-online ${
                      props?.feed?.user
                        ? (props?.feed?.user?.activity_status.toLowerCase() ==
                          "online"
                          ? "online-u"
                          : props?.feed?.user?.activity_status.toLowerCase() ==
                            "donotdisturb"
                          ? "donotdisturb-u"
                          : "offline-u")
                        : props.feed.adminuser ? (props.feed.adminuser.activity_status.toLowerCase() ==
                          "online"
                        ? "online-u"
                        : props.feed.adminuser.activity_status.toLowerCase() ==
                          "donotdisturb"
                        ? "donotdisturb-u"
                        : "offline-u") : "offline-u"
                    }`}
                  ></div>
                : <span className="post-by-status desktop-status-unknown">
                  <img src={UnknownStudentStatus} alt="unknown" />
                </span>
                }

                <UserHover
                  hideEmail={true}
                  user={props.feed.user}
                  online={`portal-status-icon ${
                    props.feed.user?.activity_status?.toLowerCase() == "online"
                      ? "online-u"
                      : props.feed.user?.activity_status?.toLowerCase() ==
                        "donotdisturb"
                      ? "donotdisturb-u"
                      : "offline-u"
                  }`}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div className="post-by-right attach-tag-rt" id="clickbox">
            <div className="ps-by-card ">
              <div className={"post-reaction-rel01 " + checkStatusAndRenderColor(row?.nestedClassStaus,"status") + (isAssessment ? " assessment-card " : "") + (isNotification ? ` notification-card ${notification?.ish_importanceActive === 1 ? " important " : ""}` : "")}>                
                <div
                  onClick={subjectCon}
                  className={
                    `${
                      isImportant ? "important-tag" : ""
                    } ps-by-card-header desc_box` +
                    (props.feed.user
                      ? props.feed.user.id
                      : props.feed.adminuser && props.feed.adminuser.id === user.id
                      ? " mypost"
                      : "")
                    + (isNew ? " new-feed-thread " : "")
                  }
                >
                  <div className="post-reat-new">
                    <div className="post-reat-head">
                      <div className="post-reat-icon">
                        <button
                          className="feed-reaction-button"
                          title="Like"
                          onClick={() => {
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 1,
                              message_id: props.feed.id,
                            });
                          }}
                        >                         
                          <img className="chat-reaction-icon" src={ThumbsUpIcon} alt="Like" />
                        </button>
                        <button
                          className="feed-reaction-button"
                          title="Dislike"
                          onClick={() => {
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 2,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <img className="chat-reaction-icon" src={ThumbsDownIcon} alt="Dislike" />
                        </button>
                        <button
                          className="feed-reaction-button"
                          title="Love"
                          onClick={() => {
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 3,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <img className="chat-reaction-icon" src={RedHeartIcon} alt="Love" />
                        </button>
                        <button
                          className="feed-reaction-button"
                          title="Laugh"
                          onClick={() => {
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 4,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <img className="chat-reaction-icon" src={GrinningIcon} alt="Laugh" />
                        </button>
                        <button
                          className="feed-reaction-button"
                          title="Surprised"
                          onClick={() => {
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 5,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <img className="chat-reaction-icon" src={OpenFaceIcon} alt="Surprised" />
                        </button>
                        <button
                          className="feed-reaction-button"
                          title="Sad"
                          onClick={() => {
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 6,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <img className="chat-reaction-icon" src={FrowningIcon} alt="Sad" />
                        </button>
                        <button
                          className="feed-reaction-button"
                          title="Angry"
                          onClick={() => {
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 7,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <img className="chat-reaction-icon" src={PoutingIcon} alt="Angry" />
                        </button>
                      </div>
                      {props.feed.user && user.id == props.feed.user.id &&
                      props.feed.userType != "admin" &&
                      !isSystemBot && !isAssessment && !isNotification ? (
                        <div className="post-reat-action">
                          <a
                            className=""
                            onClick={() => editPost(props.feed)}
                            href="#"
                            title="Edit"
                          >
                            <i className="fal fa-edit"></i>
                            {/* Edit */}
                          </a>
                          <a
                            className=""
                            href="#"
                            onClick={() => deleteConfirm(props.feed.id)}
                            title="Delete"
                          >
                            <i className="fal fa-trash-alt"></i>
                            {/* Delete */}
                          </a>
                        </div>
                      ) : null}
                      <div className="post-reat-more">
                        <a href="#!" title="More">
                          <i
                            className="fas fa-ellipsis-h"
                            onClick={() => {
                              props.setHideList(
                                props.hideList ? "" : props.feed.id
                              );
                            }}
                          ></i>
                        </a>
                      </div>
                    </div>
                    <div
                      className={`post-reat-list ${
                        props.hideList == props.feed.id ? "" : " d-none"
                      }`}
                    >
                      <h4>
                        Reactions (
                        {(props.feed &&
                        props.feed.likes &&
                        props.feed.likes.length
                          ? props.feed.likes.length
                          : 0) +
                          (props?.feed?.dislikes?.length
                            ? props.feed.dislikes.length
                            : 0) +
                          (props?.feed?.love?.length
                            ? props.feed.love.length
                            : 0) +
                          (props?.feed?.laugh?.length
                            ? props.feed.laugh.length
                            : 0) +
                          (props?.feed?.surprised?.length
                            ? props.feed.surprised.length
                            : 0) +
                          (props?.feed?.sad?.length
                            ? props.feed.sad.length
                            : 0) +
                          (props?.feed?.angry?.length
                            ? props.feed.angry.length
                            : 0)}
                        )
                      </h4>
                      <ul>
                        {renderActionList(
                          props.feed && props.feed.likes ? props.feed.likes : []
                        )}
                        {renderActionList(
                          props.feed && props.feed.dislikes
                            ? props.feed.dislikes
                            : []
                        )}
                        {renderActionList(
                          props.feed && props.feed.love ? props.feed.love : []
                        )}
                        {renderActionList(
                          props.feed && props.feed.laugh ? props.feed.laugh : []
                        )}
                        {renderActionList(
                          props.feed && props.feed.surprised
                            ? props.feed.surprised
                            : []
                        )}
                        {renderActionList(
                          props.feed && props.feed.sad ? props.feed.sad : []
                        )}
                        {renderActionList(
                          props.feed && props.feed.angry ? props.feed.angry : []
                        )}
                      </ul>
                    </div>
                  </div>
                  <h5 className="comment-con">
                    <div className="mob-profile">
                      {(props.feed.user || props.feed.adminuser) &&
                      !isSystemBot && !isAssessment && !isNotification ? (
                        <div
                          className={
                            "post-by user-profile f-m-cont-main f-m-cont-" +
                            props.ind
                          }
                        >
                          <div className="post-by-left assigned-title-blk">
                            <div className="user-drop-down-img">
                              {(props.feed &&
                                props.feed.user &&
                                props.feed.user.photo) ||
                              (props.feed &&
                                props.feed.adminuser &&
                                props.feed.adminuser.photo) ? (
                                <span className="rm-err h-100">
                                  <img
                                    src={
                                      props.feed.user
                                        ? props.feed.user.photo
                                        : props.feed.adminuser.photo
                                    }
                                    alt={InitalRender(
                                      originalValues(
                                        props.feed.user
                                          ? props.feed.user.fullname
                                          : props.feed.adminuser.fullname
                                      )
                                    )}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget
                                        .closest(".rm-err")
                                        .append(
                                          InitalRender(
                                            originalValues(
                                              props.feed.user
                                                ? props.feed.user.fullname
                                                : props.feed.adminuser.fullname,
                                              23
                                            )
                                          )
                                        );
                                      currentTarget.remove();
                                    }}
                                  />
                                </span>
                              ) : props.feed.user || props.feed.adminuser ? (
                                <>
                                  {InitalRender(
                                    originalValues(
                                      props.feed.user
                                        ? props.feed.user.fullname
                                        : props.feed.adminuser.fullname
                                    )
                                  )}
                                </>
                              ) : <img src={UnknownStudent} alt="Unknown User" />}
                            </div>
                            {props.feed.user || props.feed.adminuser ? 
                            <div
                              className={`post-by-status ${
                                props.feed.user
                                  ? props.feed.user.activity_status.toLowerCase() ==
                                    "online"
                                    ? "online-u"
                                    : props.feed.user.activity_status.toLowerCase() ==
                                      "donotdisturb"
                                    ? "donotdisturb-u"
                                    : "offline-u"
                                  : props.feed.adminuser.activity_status.toLowerCase() ==
                                    "online"
                                  ? "online-u"
                                  : props.feed.adminuser.activity_status.toLowerCase() ==
                                    "donotdisturb"
                                  ? "donotdisturb-u"
                                  : "offline-u"
                              }`}
                            ></div>
                            :
                            <span className="post-by-status desktop-status-unknown">
                              <img src={UnknownStudentStatus} alt="unknown" />
                            </span>
                            }
                            <UserHover
                              user={props.feed.user}
                              hideEmail={true}
                              online={`portal-status-icon ${
                                props.feed.user?.activity_status?.toLowerCase() ==
                                "online"
                                  ? "online-u"
                                  : props.feed.user?.activity_status?.toLowerCase() ==
                                    "donotdisturb"
                                  ? "donotdisturb-u"
                                  : "offline-u"
                              }`}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="profile-title-box-main">
                      {!isSystemBot && !isAssessment && !isNotification && (
                        <div className="profile-title-box">
                          <a>
                            {props.feed.user
                              ? props.feed.user.fullname
                              : props.feed.adminuser ? props.feed.adminuser.fullname : "Unknown User" }
                          </a>
                          <span className="post-by-time">
                            {moment(
                              props.feed.created_at,
                              "Do MMMM YYYY, h:mm:ss a"
                            ).format("dddd") +
                              ", " +
                              moment(
                                props.feed.created_at,
                                "Do MMMM YYYY, h:mm:ss a"
                              ).format(Str.TABLE_DATE_TIME_FORMAT)}
                          </span>
                          {/* <div className="new-comment">
                            {isNew ? "New" : null}
                          </div> */}
                          <small>{userRole ? rollsWidget(userRole) : ""}</small>
                        </div>
                      )}
                      <div className="mob-profile-right">
                        <div className="d-flex align-items-center">                        
                        {!isSystemBot && !isAssessment && !isNotification && (
                          <div className="mob-profile-dotted">
                            <i className="fas fa-ellipsis-v"></i>
                          </div>
                        )}
                        </div>

                        {!(props.showHidePostEdit == props.feed.id) && (
                          <>
                            <div className="post-static-input tag-point-list">
                              <div className="row_icons_fas ls_box mob-discuss-icons  mob-post-static">
                                {isMention ? (
                                  <div
                                    className=" post-reaction-icon "
                                    title="Tag"
                                  >
                                    <i className="fal fa-at"></i>
                                  </div>
                                ) : null}
                                {isImportant ? (
                                  <i
                                    className="fal fa-exclamation important-icon"
                                    title="Important"
                                  ></i>
                                ) : null}
                                {(
                                  props.feed.attachments &&
                                  props.feed.attachments.length
                                    ? props.feed.attachments.length
                                    : 0
                                ) ? (
                                  <i
                                    className="fal fa-paperclip attachment-icon"
                                    title="Attachment"
                                  ></i>
                                ) : null}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </h5>
                  {isImportant ? (
                    <p className="important-txt">IMPORTANT! </p>
                  ) : null}
                  {!isSystemBot && !isAssessment && !isNotification &&
                    !(props.showHidePostEdit == props.feed.id) && (
                      <>
                        <div className="post-static-input ">
                          <div className="row_icons_fas ls_box desk-post-static">
                            {isMention ? (
                              <div className=" post-reaction-icon " title="Tag">
                                <i className="fal fa-at"></i>
                              </div>
                            ) : null}
                            {isImportant ? (
                              <i
                                className="fal fa-exclamation important-icon"
                                title="Important"
                              ></i>
                            ) : null}
                            {(
                              props.feed.attachments &&
                              props.feed.attachments.length
                                ? props.feed.attachments.length
                                : 0
                            ) ? (
                              <i
                                className="fal fa-paperclip attachment-icon"
                                title="Attachment"
                              ></i>
                            ) : null}
                          </div>
                          <ShowMoreHide
                            show={props.feed.subject ? props.feed.subject : ""}
                            className={" post-subject-bold"}
                          />
                        </div>
                        <ShowMoreHide
                          show={props.feed.message ? props.feed.message : ""}
                          attachInfo={
                            props.feed.attachments &&
                            props.feed.attachments.length ? (
                              <FeedAttachments
                                attachments={props.feed.attachments}
                                isEditable={
                                  props.feed.id == props.showHidePostEdit
                                    ? true
                                    : false
                                }
                                allData={props.feed}
                                deletedFile={props.deletedFile}
                              />
                            ) : null
                          }
                        />
                      </>
                    )}
                  {props.showHidePostEdit == props.feed.id &&
                    editorMainThreadShow == false && (
                      <>
                        <div className="post-static-input ">
                          <div className="row_icons_fas ls_box">
                            {isMention ? (
                              <div className=" post-reaction-icon " title="Tag">
                                <i className="fal fa-at"></i>
                              </div>
                            ) : null}
                            {isImportant ? (
                              <i
                                className="fal fa-exclamation important-icon"
                                title="Important"
                              ></i>
                            ) : null}
                            {(
                              props.feed.attachments &&
                              props.feed.attachments.length
                                ? props.feed.attachments.length
                                : 0
                            ) ? (
                              <i
                                className="fal fa-paperclip attachment-icon"
                                title="Attachment"
                              ></i>
                            ) : null}
                          </div>
                          <ShowMoreHide
                            show={props.feed.subject ? props.feed.subject : ""}
                            className={" post-subject-bold"}
                          />
                        </div>
                        <ShowMoreHide
                          show={props.feed.message ? props.feed.message : ""}
                          attachInfo={
                            props.feed.attachments &&
                            props.feed.attachments.length ? (
                              <FeedAttachments
                                attachments={props.feed.attachments}
                                isEditable={
                                  props.feed.id == props.showHidePostEdit
                                    ? true
                                    : false
                                }
                                allData={props.feed}
                                deletedFile={props.deletedFile}
                              />
                            ) : null
                          }
                        />
                      </>
                    )}

                  {props.showHidePostEdit == props.feed.id &&
                    editorMainThreadShow == true && (
                      <>
                        <HtmlInputEditorShow
                          editorState={editorState}
                          setEditorState={setEditorState}
                          isInValid={editorState}
                          isImportant={isImportant}
                          setIsImportant={importantHandlePost}
                          isAttachment={
                            props.feed.attachments &&
                            props.feed.attachments.length
                              ? props.feed.attachments.length
                              : 0
                          }
                          data={props.feed.message ? props.feed.message : ""}
                          setCurrentVideo={props.setCurrentVideo}
                          showHidePostEdit={props.showHidePostEdit}
                          userData={users}
                          allData={props.feed}
                          sendClick={postEditedHandle}
                          isMention={isMention}
                        />
                        {props.feed.attachments &&
                        props.feed.attachments.length ? (
                          <FeedAttachments
                            attachments={props.feed.attachments}
                            isEditable={
                              props.feed.id == props.showHidePostEdit
                                ? true
                                : false
                            }
                            allData={props.feed}
                            deletedFile={props.deletedFile}
                          />
                        ) : null}
                      </>
                    )}
                  {isSystemBot && row?.id && (
                    <div className="system-bot">
                      <div className="">
                        <div className="system-bot-inner-R-content">
                          <div className="system-bot-inner-R-left">
                            <div className="system-bot-inner-R-head">
                              <div className="system-bot-inner-R-title">
                                <h4
                                  className={`${
                                    row?.canceled == 1
                                      ? ""
                                      : ""
                                  }`}
                                >
                                  {row?.class_title
                                    ? row?.class_title
                                    : "NA"}
                                </h4>
                                {row.class_type == "hybrid" ? (
                                  <small className="tag">
                                    <i className="fal fa-university"></i>{" "}
                                    <i className="fal fa-plus"></i>{" "}
                                    <i className="fal fa-house-signal"></i>Hybrid
                                  </small>
                                ) : row.class_type == "on_campus" ? (
                                  <small className="tag-purple">
                                    <i className="fal fa-university"></i> On Campus
                                  </small>
                                ) : (
                                  <small className="tag-green">
                                    <i className="fal fa-house-signal"></i> Virtual
                                  </small>
                                )}
                                <div
                                  className={"date-time-box mr-1 " + (row?.nestedClassStaus === "Cancelled" ? "text__strike__through" : "")}
                                >
                                  <i className="fal fa-calendar-alt"></i>
                                  <span className="ml-1">
                                    {moment(
                                      row.showDate,
                                      "dddd Do MMMM 'YYYY"
                                    ).format("dddd, DD MMM YY")}
                                    ,
                                  </span>
                                  <span >
                                    {moment(row.class_from, "HH:mm a").format(
                                      " HH:mm "
                                    )}
                                    -
                                    {moment(row.class_to, "HH:mm a").format(
                                      " HH:mm"
                                    )}{" "}
                                  </span>
                                </div>
                                <small>
                                  {classStatusWidgets(row?.nestedClassStaus)}
                                </small>
                                {/* <small className={"ml-1"}>
                                  {classStatusWidgets(row?.nestedClassSubStaus)}
                                </small> */}
                                
                                {(!row.canceled == 1 && end_time < currentTime && row?.nestedClassStaus !== "Cancelled") ?
                                  (row.percentage >= "1" ? (
                                    <div className="status-show ml-1 m-0">
                                      <b>
                                        present{" "}
                                        {row.join_type == "campus"
                                          ? "(on Campus) "
                                          : "(Online) "}
                                      </b>
                                      {/* <span className="status-percentage">{`${row?.duration
                                        ? row?.duration
                                        : "00:00:00"
                                        } ${row?.percentage
                                          ? "(" + row?.percentage + ")%"
                                          : ""
                                        }`}</span> */}
                                    </div>
                                  ) : (
                                    <div className="status-show-absent ml-1 m-0">
                                      <b>Absent</b>
                                      {/* <span className="status-percentage">{`${row?.duration ? row?.duration : "00:00:00"} ${row?.percentage
                                            ? "(" + row?.percentage + ")%"
                                            : ""
                                          }`}</span> */}
                                    </div>
                                  )) : ""
                                }
                              </div>
                              <div className="system-actions-r">                                
                                <div className="mob-profile-dotted sys-bott-dotted">
                                  <i className="fas fa-ellipsis-v"></i>
                                </div>
                              </div>
                            </div>

                            {/* {!row.canceled == 1 && ( */}
                              <div className="system-bot-inner-R-body">
                                <div className="class-schedule">
                                  <h5>
                                    {row?.class_description
                                      ? getTextContentLength(
                                          row.class_description?.trim(),
                                          110
                                        )
                                      : "NA"}
                                  </h5>

                                  {end_time < currentTime ? (
                                    <>
                                      <Popup
                                        on="click"
                                        hideOnScroll
                                        open={isOpen}
                                        onOpen={() => setIsOpen(!isOpen)}
                                        onClose={() => setIsOpen(false)}
                                        flowing
                                        position="bottom left"
                                        trigger={
                                          <div className="class-list-row row">
                                            {row?.student_rating >= 0 &&
                                              row.percentage >= "1" && (
                                                <div
                                                  className="col-12 meeting-rating-start-col m-0"
                                                  key={row.id}
                                                >
                                                  <div className="meeting-rating-start d-flex align-items-center rate-class">
                                                    <span className="stars-system-bot">
                                                      {ReactHtmlParser(star)}
                                                      {ReactHtmlParser(notStar)}
                                                    </span>
                                                    {row?.student_rating >
                                                    0 ? null : (
                                                      <span className="rate-class-text">
                                                        Rate this class
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        }
                                      >
                                        <PopupContent>
                                          <div className="row">
                                            {row.percentage >= "1" &&
                                            row.student_rating >= 0 ? (
                                              returnValue
                                            ) : (
                                              <div
                                                className="col-12 meeting-rating-start-col m-0"
                                                key={row.id}
                                              >
                                                <div className="meeting-rating-start d-flex ">
                                                  <span className="stars-system-bot">
                                                    {ReactHtmlParser(star)}
                                                    {ReactHtmlParser(notStar)}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                            {row.percentage >= "1" &&
                                            row.student_rating >= 0 ? (
                                              returnValue1
                                            ) : (
                                              <div
                                                className="col-12 meeting-leave-comment-col m-0"
                                                key={row.id}
                                              >
                                                <span>
                                                  {" "}
                                                  {row.student_comment?.trim()}
                                                </span>{" "}
                                              </div>
                                            )}
                                          </div>
                                        </PopupContent>
                                      </Popup>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <div className="d-flex justify-content-between">
                                    <div className="class__schedule__venues">
                                      {row?.campus_name_room &&
                                        row?.campus_name_room != "" && (
                                          <ul className="class-schedule-list mt-2">
                                            {ReactHtmlParser(
                                              row.campus_name_room.replaceAll(
                                                "fa-li ",
                                                ""
                                              )
                                            )}
                                          </ul>
                                        )}

                                      {row?.Firstname && row?.Lastname && (
                                        <div className="name-user">
                                          <p>
                                            <i className="far fa-chalkboard-teacher"></i>
                                            {`${row?.Firstname} ${row?.Lastname}`}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                    <div className="btn-group-box mt-auto chatroom__btn__grp">
                                      {row?.canceled == 1 ? (
                                        <div className="">
                                          {row.recordings != "" &&
                                          row.recordings != null ? (
                                            <ul className="meeting-btns-list">
                                              {ReactHtmlParser(row.recordings)}
                                            </ul>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : end_time < currentTime ? (
                                        <div className="">
                                          {row.recordings != "" &&
                                          row.recordings != null ? (
                                            <ul className="meeting-btns-list">
                                              {ReactHtmlParser(row.recordings)}
                                            </ul>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : start_time_live <= currentTime &&
                                        end_time >= currentTime ? (
                                        <>
                                          {row.class_type == "hybrid" ? (
                                            <div className="">
                                              {row.disable_online == "" ? (
                                                <button
                                                  disabled={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ? false : true}
                                                  data-id={`${row.id}`}
                                                  title="Join the class virtually if you are not on campus."
                                                  onClick={() =>
                                                    joinVirtualClassGlobal(row.id)
                                                  }
                                                  className={`btn btn-primary mr-2 join-virtual-classes-list-view`}
                                                >
                                                  <i className="fal fa-wifi"></i>
                                                  Join Virtual Class
                                                </button>
                                              ) : (
                                                <button
                                                  data-id={`${row.id}`}
                                                  title="Join the class virtually if you are not on campus."
                                                  onClick={() =>
                                                    joinVirtualClassGlobal(row.id)
                                                  }
                                                  disabled
                                                  className={`btn btn-primary mr-2 join-virtual-classes-list-view disabled`}
                                                >
                                                  <i className="fal fa-wifi"></i>
                                                  Join Virtual Class
                                                </button>
                                              )}

                                              {row.disable_campus != "" &&
                                              row.hide_checkin != "" ? (
                                                <button
                                                  data-id={`${row.id}`}
                                                  title="Check in here if you are attending the class on campus."
                                                  disabled
                                                  onClick={() =>
                                                    joinCampusClassGlobal(row?.id)
                                                  }
                                                  style={{ display: "none" }}
                                                  className={`btn btn-primary mr-2 join-campus-classes-list-view disabled`}
                                                >
                                                  <i className="fal fa-university"></i>
                                                  Check In On Campus
                                                </button>
                                              ) : row.disable_campus != "" &&
                                                row.hide_checkin == "" ? (
                                                <button
                                                  data-id={`${row.id}`}
                                                  title="Check in here if you are attending the class on campus."
                                                  onClick={() =>
                                                    joinCampusClassGlobal(row?.id)
                                                  }
                                                  disabled
                                                  className={`btn btn-primary mr-2 join-campus-classes-list-view disabled`}
                                                >
                                                  <i className="fal fa-university"></i>
                                                  Check In On Campus
                                                </button>
                                              ) : row.disable_campus == "" &&
                                                row.hide_checkin != "" ? (
                                                <button
                                                  data-id={`${row.id}`}
                                                  title="Check in here if you are attending the class on campus."
                                                  style={{ display: "none" }}
                                                  onClick={() =>
                                                    joinCampusClassGlobal(row?.id)
                                                  }
                                                  className={`btn btn-primary mr-2 join-campus-classes-list-view`}
                                                >
                                                  <i className="fal fa-university"></i>
                                                  Check In On Campus
                                                </button>
                                              ) : (
                                                <button
                                                  disabled={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ? false : true}
                                                  data-id={`${row.id}`}
                                                  title="Check in here if you are attending the class on campus."
                                                  className={`btn btn-primary mr-2 join-campus-classes-list-view`}
                                                  onClick={() =>
                                                    joinCampusClassGlobal(row?.id)
                                                  }
                                                >
                                                  <i className="fal fa-university"></i>
                                                  Check In On Campus
                                                </button>
                                              )}

                                              {row.hide_checkout == "" ? (
                                                <button
                                                  data-id={`${row.id}`}
                                                  title="On Campus Check Out"
                                                  className="btn mr-2 exit-campus-class exit-campus-classes-list-view"
                                                  onClick={() =>
                                                    exitCampusClassGlobal(row?.id)
                                                  }
                                                >
                                                  <i
                                                    className="fal fa-window-close"
                                                    aria-hidden="true"
                                                  ></i>
                                                  On Campus Check Out
                                                </button>
                                              ) : (
                                                <button
                                                  data-id={`${row.id}`}
                                                  title="On Campus Check Out"
                                                  style={{ display: "none" }}
                                                  onClick={() =>
                                                    exitCampusClassGlobal(row?.id)
                                                  }
                                                  className="btn mr-2 exit-campus-class exit-campus-classes-list-view"
                                                >
                                                  <i
                                                    className="fal fa-window-close"
                                                    aria-hidden="true"
                                                  ></i>
                                                  On Campus Check Out
                                                </button>
                                              )}
                                            </div>
                                          ) : row.class_type == "on_campus" ? (
                                            <div className="">
                                              {row.hide_checkin == "" ? (
                                                <button
                                                  disabled={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ? false : true}
                                                  data-id={`${row.id}`}
                                                  title="Check in here if you are attending the class on campus."
                                                  className="btn btn-primary mr-2 join-campus-classes-list-view"
                                                  onClick={() =>
                                                    joinCampusClassGlobal(row?.id)
                                                  }
                                                >
                                                  <i className="fal fa-university"></i>
                                                  Check In On Campus
                                                </button>
                                              ) : (
                                                <button
                                                  data-id={`${row.id}`}
                                                  title="Check in here if you are attending the class on campus."
                                                  onClick={() =>
                                                    joinCampusClassGlobal(row?.id)
                                                  }
                                                  style={{ display: "none" }}
                                                  className="btn btn-primary mr-2 join-campus-class join-campus-classes-list-view"
                                                >
                                                  <i className="fal fa-university"></i>
                                                  Check In On Campus
                                                </button>
                                              )}

                                              {row.hide_checkout == "" ? (
                                                <button
                                                  data-id={`${row.id}`}
                                                  title="On Campus Check Out"
                                                  className="btn mr-2 exit-campus-class exit-campus-classes-list-view"
                                                  onClick={() =>
                                                    exitCampusClassGlobal(row?.id)
                                                  }
                                                >
                                                  <i
                                                    className="fal fa-window-close"
                                                    aria-hidden="true"
                                                  ></i>
                                                  On Campus Check Out
                                                </button>
                                              ) : (
                                                <button
                                                  data-id={`${row.id}`}
                                                  title="On Campus Check Out"
                                                  className="btn mr-2 exit-campus-class exit-campus-classes-list-view"
                                                  style={{ display: "none" }}
                                                  onClick={() =>
                                                    exitCampusClassGlobal(row?.id)
                                                  }
                                                >
                                                  <i
                                                    className="fal fa-window-close"
                                                    aria-hidden="true"
                                                  ></i>
                                                  On Campus Check Out
                                                </button>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="">
                                              <button
                                                disabled={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ? false : true}
                                                data-id={`${row.id}`}
                                                title="Join the class virtually if you are not on campus."
                                                onClick={() =>
                                                  joinVirtualClassGlobal(row.id)
                                                }
                                                className="btn btn-primary mr-2 join-virtual-classes-list-view "
                                              >
                                                <i className="fal fa-wifi"></i>
                                                Join Virtual Class
                                              </button>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {row.class_type == "hybrid" ? (
                                            <div className="">
                                              <button
                                                data-id={`${row.id}`}
                                                title="Join the class virtually if you are not on campus."
                                                onClick={() =>
                                                  joinVirtualClassGlobal(row.id)
                                                }
                                                className={`btn btn-primary mr-2 join-virtual-class join-virtual-classes-list-view`}
                                                disabled
                                              >
                                                <i className="fal fa-house-signal"></i>
                                                <span className="btn-text">
                                                  Join Virtual Class
                                                </span>
                                              </button>
                                              <button
                                                data-id={`${row.id}`}
                                                title="Check in here if you are attending the class on campus."
                                                disabled
                                                className={`btn btn-primary mr-2 join-campus-class join-campus-classes-list-view`}
                                                onClick={() =>
                                                  joinCampusClassGlobal(row?.id)
                                                }
                                              >
                                                <i className="fal fa-university"></i>
                                                <span className="btn-text">
                                                  Check In On Campus
                                                </span>
                                              </button>
                                            </div>
                                          ) : row.class_type == "on_campus" ? (
                                            <div className="">
                                              <button
                                                data-id={`${row.id}`}
                                                disabled
                                                title="Check in here if you are attending the class on campus."
                                                className="btn btn-primary mr-2 join-campus-class join-campus-classes-list-view"
                                                onClick={() =>
                                                  joinCampusClassGlobal(row?.id)
                                                }
                                              >
                                                <i className="fal fa-university"></i>
                                                <span className="btn-text">
                                                  Check In On Campus
                                                </span>
                                              </button>
                                            </div>
                                          ) : (
                                            <div className="">
                                              <button
                                                data-id={`${row.id}`}
                                                disabled
                                                title="Join the class virtually if you are not on campus."
                                                onClick={() =>
                                                  joinVirtualClassGlobal(row.id)
                                                }
                                                className="btn btn-primary mr-2 join-virtual-class join-virtual-classes-list-view "
                                              >
                                                <i className="fal fa-house-signal"></i>
                                                <span className="btn-text">
                                                  Join Virtual Class
                                                </span>
                                              </button>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {isAssessment && assessment?.id && (
                    <div className="system-bot">
                      <div className="">
                        <div className="system-bot-inner-R-content">
                          <div className="system-bot-inner-R-left">
                            <div className="system-bot-inner-R-head">
                              <div className="system-bot-inner-R-title">
                                <h4>{assessment?.name ? assessment?.name : "NA"}</h4>
                                <span className="lib-con">
                                  {RenderFeedAssessmentType(assessment?.ass_type).html}
                                </span>                                
                                {assessment?.ass_type != "Survey" ?(assessment?.status=="Open" ?
                                <span className="assessment-conditional-detail">
                                  <i className="fal fa-clock"></i> {`${assessment?.timeHour.toString().padStart(2,'0')}h:${assessment?.timeMin.toString().padStart(2,'0')}m`}                                  
                                </span>
                                : (assessment?.status == "Closed-Submitted" || assessment?.status=="Submitted") ? (user.status == "Results Witheld" && isWithHeldStatus == "true") ? 
                                (<span className="assessment-conditional-detail feed-result-withheld">
                                  RESULTS WITHHELD
                                </span>)
                                :(<span className="assessment-conditional-detail">
                                <i class="fal fa-calendar-edit"></i> {RenderMarkingCountdown(assessment?.marking_deadline_date)}
                                </span>)                                   
                                :(assessment?.status=="Closed-Marked" || assessment?.status=="Closed Not-Submitted") && assessment?.ass_type!="Survey" ?                                   
                                (user.status == "Results Witheld" && isWithHeldStatus == "true") ? 
                                <span className="assessment-conditional-detail feed-result-withheld">
                                  RESULTS WITHHELD
                                </span>
                                :<span className="assessment-conditional-detail">
                                  <i className="fal fa-ballot-check"></i> Result: 
                                  <span className="font-weight-bold">
                                    {assessment?.grading_type === 3 ? 
                                      ` ${twoDecimalRound(assessment?.assCalculateGradeStatus?.gradeText)}/${assessment?.subAssTotMark}` 
                                      : assessment?.grading_type === 0 ? 
                                      ` ${twoDecimalRound(assessment?.assCalculateGradeStatus?.grade)}%` 
                                      : ` ${(assessment?.assCalculateGradeStatus?.gradeText)}`
                                    }
                                  </span>
                                </span>
                                :null)
                                :null}                                
                                {RenderFeedAssessmentStatus(assessment?.status).html}
                              </div>
                              <div className="system-actions-r">                                
                                <div className="mob-profile-dotted sys-bott-dotted">
                                  <i className="fas fa-ellipsis-v"></i>
                                </div>
                              </div>
                            </div>
                            <div className="system-bot-inner-R-body">
                              <div className="feed-assessment-info">
                                <div className="assessment-date-container">
                                  <i className="fal fa-calendar-alt mr-2"></i>
                                  <span>
                                    {`${moment.unix(assessment?.open_date_time).format("dddd")}, ${moment.unix(assessment?.open_date_time).format(Str.TABLE_DATE_TIME_FORMAT)}`} - {`${moment.unix(assessment?.close_date_time).format("dddd")}, ${moment.unix(assessment?.close_date_time).format(Str.TABLE_DATE_TIME_FORMAT)}`}
                                  </span>
                                </div>  
                                {(assessment.camera || assessment.audio || assessment.screen_share || assessment.timeLimitActive) ? 
                                  <div className="assessment-condition-container">
                                    <span>
                                      Conditions: {assessment.camera ? <i data-toggle="tooltip" title="This exam requires your computer/Laptop to have an operational webcam" className="fal fa-webcam active"></i> : null}
                                      {assessment.audio ? <i data-toggle="tooltip" title="This exam requires your computer/Laptop to have an operational microphone" className="fal fa-microphone"></i> : null}
                                      {assessment.screen_share ? <i data-toggle="tooltip" title="This exam will record the contents of your screen, please have one screen and disconnect secondary screens" className="fal fa-desktop"></i> : null}
                                      {assessment.timeLimitActive ? <i data-toggle="tooltip" title="This is a timed exam" className="fal fa-clock"></i> : null}
                                    </span>
                                  </div>
                                :null}
                              </div>
                              <div className="feed-assessment-action">
                                <Link 
                                  className="btn btn-primary"
                                  target="_blank"
                                  to={`/course/${qid}/subject/${assessment?.intake}/assessment/${assessment?.id}`}                                  
                                >
                                  <i className="fal fa-file-alt"></i> Open Assessment
                                </Link>
                              </div>
                            </div>                            
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isNotification && notification?.id && (
                    <div className="system-bot">
                      <div className="">
                        <div className="system-bot-inner-R-content">
                          <div className="system-bot-inner-R-left">
                            <div className="system-bot-inner-R-head">
                              <div className="system-bot-inner-R-title">
                                <h4>{notification?.subject ? notification?.subject : "NA"}</h4>                                
                              </div>
                              <div className="system-actions-r">                                
                                <div className="mob-profile-dotted sys-bott-dotted">
                                  <i className="fas fa-ellipsis-v"></i>
                                </div>
                              </div>
                            </div>
                            <div className="system-bot-inner-R-body">
                              <div className="feed-assessment-info">
                                <div className="">
                                  <i className="fal fa-calendar-alt mr-2"></i>
                                  <span>
                                    {`${moment.unix(notification?.start).format("dddd")}, ${moment.unix(notification?.start).format(Str.TABLE_DATE_TIME_FORMAT)}`}
                                  </span>
                                </div>                                  
                              </div>
                              <div className="feed-assessment-action">
                                <Link 
                                  className="btn btn-primary"
                                  target="_blank"
                                  to={`/mynotifications/${notification?.id}`}                                  
                                >
                                  <i className="fal fa-bell"></i> View Notification
                                </Link>
                              </div>
                            </div>                            
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="chat-reaction-bar">
                    {props.feed && props.feed.likes && props.feed.likes.length ? (
                      <button className={"chat-message-reaction " + (isLiked(user.id, props.feed.likes ? props.feed.likes : []) ? "active" : "")} title="Like" onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: props.feed.id,
                          table_type: "message",
                          comment_type: 1,
                          message_id: props.feed.id,
                        });
                      }}>
                          <img className="chat-reaction-icon" src={ThumbsUpIcon} alt="Like" /> <span>{props.feed.likes.length}</span>
                      </button>
                    ) : null}
                    {props.feed && props.feed.dislikes && props.feed.dislikes.length ? (
                      <button className={"chat-message-reaction " + (isDisliked(user.id, props.feed.dislikes ? props.feed.dislikes : []) ? "active" : "")} Title="Dislike" onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: props.feed.id,
                          table_type: "message",
                          comment_type: 2,
                          message_id: props.feed.id,
                        });
                      }}>
                          <img className="chat-reaction-icon" src={ThumbsDownIcon} alt="Dislike" /> <span>{props.feed.dislikes.length}</span>
                      </button>
                    ) : null}
                    {props.feed && props.feed.love && props.feed.love.length ? (
                      <button className={"chat-message-reaction " + (isLoved(user.id, props.feed.love ? props.feed.love : []) ? "active" : "")} Title="Love" onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: props.feed.id,
                          table_type: "message",
                          comment_type: 3,
                          message_id: props.feed.id,
                        });
                      }}>
                          <img className="chat-reaction-icon" src={RedHeartIcon} alt="Love" /> <span>{props.feed.love.length}</span>
                      </button>
                    ) : null}
                    {props.feed && props.feed.laugh && props.feed.laugh.length ? (
                      <button className={"chat-message-reaction " + (isLaughed(user.id, props.feed.laugh ? props.feed.laugh : []) ? "active" : "")} title="Laugh" onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: props.feed.id,
                          table_type: "message",
                          comment_type: 4,
                          message_id: props.feed.id,
                        });
                      }}>
                          <img className="chat-reaction-icon" src={GrinningIcon} alt="Laugh" /> <span>{props.feed.laugh.length}</span>
                      </button>
                    ) : null}
                    {props.feed && props.feed.surprised && props.feed.surprised.length ? (
                      <button className={"chat-message-reaction " + (isSurprised(user.id, props.feed.surprised ? props.feed.surprised : []) ? "active" : "")} title="Surprised" onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: props.feed.id,
                          table_type: "message",
                          comment_type: 5,
                          message_id: props.feed.id,
                        });
                      }}>
                          <img className="chat-reaction-icon" src={OpenFaceIcon} alt="Surprised" /> <span>{props.feed.surprised.length}</span>
                      </button>
                    ) : null}
                    {props.feed && props.feed.sad && props.feed.sad.length ? (
                      <button className={"chat-message-reaction " + (isSad(user.id, props.feed.sad ? props.feed.sad : []) ? "active" : "")} title="Sad" onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: props.feed.id,
                          table_type: "message",
                          comment_type: 6,
                          message_id: props.feed.id,
                        });
                      }}>
                          <img className="chat-reaction-icon" src={FrowningIcon} alt="Sad" /> <span>{props.feed.sad.length}</span>
                      </button>
                    ) : null}
                    {props.feed && props.feed.angry && props.feed.angry.length ? (
                      <button className={"chat-message-reaction " + (isAngry(user.id, props.feed.angry ? props.feed.angry : []) ? "active" : "")} title="Angry" onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: props.feed.id,
                          table_type: "message",
                          comment_type: 7,
                          message_id: props.feed.id,
                        });
                      }}>
                          <img className="chat-reaction-icon" src={PoutingIcon} alt="Angry" /> <span>{props.feed.angry.length}</span>
                      </button>
                    ) : null}                      
                  </div>
                </div>
              </div>

              <div className="ps-by-card-body spacing_lt-rt">
                {comments && comments.length ? (
                  <FeedComments
                    comments={comments}
                    user={user}
                    online={props.online}
                    setCurrentVideo={props.setCurrentVideo}
                    likeDislikeHandle={likeDislikeHandle}
                    deleteHandle={deleteHandle}
                    editHandle={editHandle}
                    userData={users}
                    updateMsg={props.updateMsg}
                    setShowHideEdit={props.setShowHideEdit}
                    showHideEditArr={props.showHideEditArr}
                    resetEditor={subjectCon}
                    readMessages={
                      props.feed.readMessages ? props.feed.readMessages : null
                    }
                    reduceCount={props.reduceCount}
                    room_id={props.feed.room_id}
                    setShowHideCOmm={setShowHideCOmm}
                    showHideCOmm={showHideCOmm}
                    childFun={childFun}
                    updateReadMessages={props.updateReadMessages}
                    userRole={userRole}
                    subjectId={props.subjectId}
                    teamObj={props.teamObj}
                    showReplyEditor={handleShowReplyEditor}                    
                    message_id={props?.feed?.id}
                  />
                ) : null}                
              </div>
              <div ref={editorRef}>
                {!editorShow ? (
                  <div
                    className={"reply-static "+props.ind+" reply-"+props.ind}
                    onClick={() => {
                      showHideReply();
                    }}
                  >
                    <i className="fal fa-reply"></i>Reply
                  </div>
                ) : (
                  <div className={"ps-by-card-footer new-reply-box reply-box-toggle-"+props.ind} name={"reply-button"}>
                    <div className="card-footer" id={`reply-input-editor-${props?.feed?.id}`}>
                      <div className="card-left assigned-title-blk online-status">
                        <div className="card-left-by-img">
                          {user.photo ? (
                            <img src={user.photo} alt={user.fullname} />
                          ) : (
                            <div> {InitalRender(user.fullname)}</div>
                          )}
                        </div>
                        <span
                          className={`portal-status-icon ${
                            user?.activity_status?.toLowerCase() == "online"
                              ? "online-u"
                              : user?.activity_status?.toLowerCase() ==
                                "donotdisturb"
                              ? "donotdisturb-u"
                              : "offline-u"
                          }`}
                        ></span>
                        <UserHover
                          user={user}
                          hideEmail={true}
                          online={`portal-status-icon ${
                            user?.activity_status?.toLowerCase() == "online"
                              ? "online-u"
                              : user?.activity_status?.toLowerCase() ==
                                "donotdisturb"
                              ? "donotdisturb-u"
                              : "offline-u"
                          }`}
                        />
                      </div>
                      <div className="card-right">
                        <div className="" onDragEnter={handleDragEnter}>
                          <HtmlInputEditor
                            editorState={editorState2}
                            setEditorState={setEditorState2}
                            isInValid={editorState2}
                            data={users}
                            placeholder="Reply"
                            attachmentClick={handleAttachment}
                            sendClick={() => {
                              sendComment();
                              readComment(
                                comments[comments.length - 1]?.message_id,
                                comments[comments.length - 1]?.id
                              );
                            }}
                            attachments={attachments}
                            setIsImportant={setIsImportantReply}
                            isImportant={isImportantReply}
                            showcurseragain={showcursure}
                            showReplyTo={showReplyTo}
                            setShowReplyTo={setShowReplyTo}
                            replyToData = {replyToData}
                            closeReplyTo={handleRemoveReply}
                          />
                          <input
                            type="file"
                            ref={btnAttachment}
                            style={{ display: "none" }}
                            onChange={(e) => handleFiles(e)}
                          />
                        </div>
                        <div
                          className={
                            !showFilePond ? "hideFilePond" : "showFilePond"
                          }
                        >
                          <FilePond
                            name="post"
                            allowMultiple={true}
                            maxFiles={3}
                            ref={filePondRef}
                            files={files}
                            onupdatefiles={setFiles}
                            beforeRemoveFile={(f) => {
                              setRemoveFile(f.file.name);
                              attachmentsArrUpdate(f.file.name);
                            }}
                            // onremovefile={(err, file)=>{console.log('the removed file is ', file)}}
                            onaddfile={(err, fileItem) => {
                              setShowFilePond(true);
                            }}
                            server={{
                              url: API_BASE,
                              process: {
                                url: "/addFeedAttachments",
                                headers: {
                                  Authorization: `Bearer ${getToken()}`,
                                },
                                onload: (response) => {
                                  handleUploadedFiles(response);
                                },
                                ondata: (formData) => {
                                  formData.append("datasize", true);
                                  return formData;
                                },
                              },
                              revert: {
                                url: `/removeFeedAttachments/${removeFile}`,
                                headers: {
                                  Authorization: `Bearer ${getToken()}`,
                                },
                                onload: (response) => {
                                  setRemoveFile(null);
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>              
            </div>
          </div>
        </div>      
    </>
  );
});

export default React.memo(FeedMessage);


